@import url("https://fonts.googleapis.com/css2?family=Orbitron&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Makasar&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Recursive&display=swap");

* {
  padding: 0;
  margin: 0;
}

html,
body {
  width: 100vw;
  height: 100vh;
}

.bgImg {
  width: 100vw;
  height: 100vh;
  z-index: -100;
  position: absolute;
}

.logo {
  width: 15vw;
  position: absolute;
  margin: 10px;
}

header {
  width: 100vw;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  color: #8ac242;
  font-family: "Kaushan Script", "Recursive";
}

main {
  width: 100vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.comingSoon {
  font-size: 100px;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Noto Serif Makasar", serif;
}

main hr {
  width: 50%;
  border: #ffffff solid 1px;
  margin: 15px 0;
}

.counter {
  font-size: xx-large;
  color: #ffffff;
  font-family: "Orbitron", sans-serif;
  text-transform: uppercase;
}

footer {
  width: 100vw;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

footer a {
  color: white;
  text-decoration: none;
}
